@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Thai:wght@400&display=swap'); 
body {
  margin: 0;
  font-family: 'IBM Plex Sans Thai', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'IBM Plex Sans Thai', sans-serif !important;
}


.form-input{
 text-align: center;
 /* margin-top: 10px; */

}

.text-field {
 width: 100%;
}

.divider {
 border-top: 1px solid #000000; /* Adjust color and size as needed */
}

.backgroud-formInput {
  color: aliceblue;
  border-radius: 10px 0px;
  background-color: #025464bf;
  /* box-sizing: content-box; */
  margin: 10px 0px 30px 0px;
  padding: 10px;
  width: 300px;
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: center;
}