@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Thai:wght@400&display=swap");
/* @import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Thai:wght@300;400;600&display=swap'); */
body {
  margin: 0;
  font-family: "IBM Plex Sans Thai", sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

code {
  font-family: "IBM Plex Sans Thai", sans-serif;
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
}

/* MyMap CSS */
#map {
  width: 100%;
  height: 91vh;
  overflow: hidden;
}

body {
  font-family: "IBM Plex Sans Thai", sans-serif;
  /* font-family: Verdana, Geneva, Tahoma, sans-serif; */
}

:root {
  --clr-primary: #ee6352;
  --clr-primary-hover: #ff1e00;
  --clr-button-clicked: #f7ff07;
  --clr-body: #333;
  --clr-bg: white;
  --size-button: 30px;
}

.featuresInfoPopup {
  position: absolute;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  padding: 15px;
  border-radius: 10px;
  border: 3px;
  bottom: 12px;
  left: -50px;
  min-width: 280px;
  display: none;
}

.featuresInfoPopup:after,
.featuresInfoPopup:before {
  top: 100%;
  border: solid transparent;
  content: " ";
  /* height: 0;
  width: 0; */
  position: absolute;
  pointer-events: none;
}

.featuresInfoPopup:after {
  border-top-color: white;
  border-width: 10px;
  left: 48px;
  margin-left: -10px;
}

.featuresInfoPopup:before {
  border-top-color: #cccccc;
  border-width: 11px;
  left: 48px;
  margin-left: -11px;
}

.controlsButton {
  background-color: var(--clr-primary);
  font-weight: 700;
  text-align: center;
  border: none;
  border-radius: 10px;
  padding: 10px;
  align-items: center;
  cursor: pointer;
}

.controlsButton:hover {
  background-color: var(--clr-primary-hover);
}

.controlsButton.clicked {
  background-color: rgba(22, 170, 35, 0.8);
}

.controlsButton.clicked:hover {
  background-color: rgba(22, 170, 35, 1);
}

.homeButtonDiv {
  position: flex;
  display: block;
  margin: 10px auto auto 10px;
  width: 45px;
}

.fullscreenButtonDiv {
  position: absolute;
  display: block;
  top: 0;
  right: 0;
  margin-right: 10px;
  margin-top: 10px;
}

.featureInfoDiv {
  position: relative;
  display: block;
  margin: 5px auto auto 10px;
  width: 45px;
}

.queryButtonDiv {
  position: relative;
  display: block;
  margin: 5px auto auto 10px;
  width: 45px;
}

.surveyAlertButtonDiv {
  position: relative;
  display: block;
  margin: 5px auto auto 10px;
  width: 45px;
}

.attributeQueryDiv {
  position: absolute;
  top: 0;
  left: 0;
  margin-right: 10px;
  margin-left: 60px;
  margin-top: 10px;
  border: 2px solid #ffff;
  border-radius: 10px;
  background: var(--clr-bg);
  padding: 10px 20px 10px 10px;
  display: none;
  align-items: center;
}

#measureLengthButton {
  position: relative;
  margin: 5px auto auto 10px;
  width: 45px;
  display: block;
}

#measureAreaButton {
  position: relative;
  margin: 5px auto auto 10px;
  width: 45px;
  display: block;
}

.attributeQueryDiv select {
  border: 0;
  flex-grow: 1;
  font-size: 0.9rem;
  padding: 0 0.5em;
  border-radius: 20px;
  background: var(--clr-bg);
  outline: 0;
}

.attributeQueryDiv input {
  border: 0;
  flex-grow: 1;
  font-size: 0.9rem;
  padding: 0 0.5em;
  width: 100px;
  background: var(--clr-bg);
  outline: 0;
  height: 55px;
}

.attributeQueryDiv input::placeholder {
  color: rgba(211, 211, 211, 0.79);
}

.attritbuteQuerySubmit {
  font-size: 2rem;
  cursor: pointer;
  border: 0;
  background: transparent;
  border-radius: 50%;
  background: var(--clr-primary);
  /* width:10%; */
  height: 40px !important;
  padding: 5px;
  color: #ffff;
  background: #8d1f78 !important;
}

/* .popup-content {
  margin-bottom: 5px;
} */

/* fontAwesome */
.fasSearch {
  filter: invert(1);
}

.surveyInput {
  height: 20px;
  width: 20px;
  filter: invert(1);
}

.group {
  border: 2px solid #ffff;
  border-radius: 100px;
  padding: 5px;
}

.layer-switcher.shown .panel {
  display: flex;
  border: 2px solid none;
  border-radius: 10px;
  padding: 5px;
  width: 250px;
}

.layer-switcher.shown button {
  /* color: #ff1e00 !important; */
  background-color: #29496252 !important ;
  display: none;
  /* border: 2px solid #fff; */
  border-radius: 10px;
  padding: 5px;
}

.surveyButton {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 5px;
  margin-right: 5px;
}

/* Measure CSS */
.tooltip {
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  color: white;
  padding: 4px 8px;
  opacity: 0.7;
  white-space: nowrap;
}

.tooltip-measure {
  opacity: 1;
  font-weight: bold;
}

.tooltip-static {
  background-color: #ffcc33;
  color: black;
  border: 1px solid white;
}

.tooltip-measure:before,
.tooltip-static:before {
  border-top: 6px solid rgba(0, 0, 0, 0.5);
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  content: "";
  position: absolute;
  bottom: -6px;
  margin-left: -7px;
  left: 50%;
}

.tooltip-static:before {
  border-top-color: #ffcc33;
}

/* End Measure CSS */

.layer-switcher {
  position: absolute;
  top: 3.5em;
  right: 0.5em;
  text-align: left;
}

.layer-switcher .panel {
  margin: 0;
  border: 4px solid #fff;
  border-radius: 4px;
  background-color: white;
  display: none;
  max-height: inherit;
  height: 100%;
  box-sizing: border-box;
  overflow-y: auto;
}

.layer-switcher button {
  /* float: right; */
  z-index: 1;
  width: 40px;
  height: 40px;
  border-radius: 10px;
  background-image: url("../public/layer-group-solid.png");
  background-repeat: no-repeat;
  background-position: 3px;
  background-color: #0fa3c1;
  padding: 2px 2px 2px 2px;
  background-size: 80%;
  border: #fff;
  filter: invert(1);
}

/* .layer-switcher button:focus, */
.layer-switcher button:hover {
  background-color: #0bd1f9 !important;
  color: white !important;
}

.layer-switcher.shown {
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 5.5em);
}

.layer-switcher.shown.ol-control {
  background-color: transparent;
}

.layer-switcher.shown.ol-control:hover {
  background-color: transparent;
}

.layer-switcher.shown .panel {
  display: block;
  width: 300px;
}

.layer-switcher.shown button {
  background-color: #0fa3c100 !important ;
  color: #0bd1f9;
  display: none;
  font-size: 30px;
}

.layer-switcher.shown.layer-switcher-activation-mode-click > button {
  display: block;
  background-image: unset;
  right: 2px;
  position: absolute;
  background-color: #eee;
  margin: 0 1px;
}

.layer-switcher.shown button:focus,
.layer-switcher.shown button:hover {
  background-color: #fafafa;
}

.layer-switcher ul {
  list-style: none;
  margin: 1.6em 0.4em;
  padding-left: 0;
}

.layer-switcher ul ul {
  padding-left: 1.2em;
  margin: 0.1em 0 0 0;
}

.layer-switcher li.group + li.group {
  margin-top: 0.4em;
}

.layer-switcher li.group > label {
  font-weight: bold;
}

.layer-switcher.layer-switcher-group-select-style-none li.group > label {
  padding-left: 1.2em;
}

.layer-switcher li {
  position: relative;
  margin-top: 0.3em;
}

.layer-switcher li input {
  position: absolute;
  left: 1.2em;
  height: 1em;
  width: 1em;
  font-size: 1em;
}

.layer-switcher li label {
  padding-left: 2.7em;
  padding-right: 1.2em;
  display: inline-block;
  margin-top: 1px;
}

.layer-switcher label.disabled {
  opacity: 0.4;
}

.layer-switcher input {
  margin: 0px;
}

.layer-switcher.touch ::-webkit-scrollbar {
  width: 4px;
}

.layer-switcher.touch ::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.layer-switcher.touch ::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

li.layer-switcher-base-group > label {
  padding-left: 1.2em;
}

.layer-switcher .group button {
  position: absolute;
  left: 0;
  display: inline-block;
  vertical-align: top;
  float: none;
  font-size: 1em;
  width: 1em;
  height: 1em;
  margin: 0;
  background-position: center 2px;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAW0lEQVR4nGNgGAWMyBwXFxcGBgaGeii3EU0tXHzPnj1wQRYsihqQ+I0ExDEMQAYNONgoAN0AmMkNaDSyQSheY8JiaCMOGzE04zIAmyFYNTMw4A+DRhzsUUBtAADw4BCeIZkGdwAAAABJRU5ErkJggg==");
  -webkit-transition: -webkit-transform 0.2s ease-in-out;
  -ms-transition: -ms-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
}

.layer-switcher .group.layer-switcher-close button {
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
}

.layer-switcher .group.layer-switcher-fold.layer-switcher-close > ul {
  overflow: hidden;
  height: 0;
}

/*layerswitcher on the right*/
.layer-switcher.shown.layer-switcher-activation-mode-click {
  padding-left: 34px;
}

.layer-switcher.shown.layer-switcher-activation-mode-click > button {
  /* left: 0; */
  border-right: 0;
}

/*layerswitcher on the left*/
/*
.layer-switcher.shown.layer-switcher-activation-mode-click {
  padding-right: 34px;
}
.layer-switcher.shown.layer-switcher-activation-mode-click > button {
  right: 0;
  border-left: 0;
}
*/

/* APP BAR CSS */
/* .sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: rgb(2, 84, 100) !important;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
  cursor: pointer;
} */

/* .sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  color: #fff;
  display: block;
  transition: 0.3s;
  /* border-left: 4px solid #fff; */
/* }  */

/* .sidenav a:hover {
  color: rgb(2, 84, 100) !important;
  background-color: #fff;
  border-left: 4px solid rgb(2, 84, 100) !important;
} */

/* .sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
} */

/* Survey App CSS */
/* .otherSide {
  transition: margin-left .5s;
  background-color: #f7f2ec;
  position: absolute; 
  min-width: -webkit-fill-available;
  min-height: -webkit-fill-available;
}  */

/* .scrollbar-container {
  overflow-y: scroll;
  ;
  width: 100%;
  height: 100%;
} */

/* width and height of the scrollbar */
::-webkit-scrollbar {
  width: 10px;
  /* height: 15px; */
}

/* background of the scrollbar track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* color of the scrollbar thumb */
::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
  border-radius: 20px;
  border: 1px solid #fafafa;
}

/* color on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.surveyCardBody {
  background-color: #f2f7f8;
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }

  .sidenav a {
    font-size: 18px;
  }
}

.box-login {
  margin: 0px !important;
  /* margin-left: 0px !important; */
}

/* surveyLayouts */
.surveyLayouts {
  margin-left: 35px;
}

/* Page search List */
.box-form {
  border-radius: 10px;
  box-shadow: 1px 1px 1px #8c8c8c;
  background-color: #fffbe6;
  /* margin-top: 20px;
  margin-right: 20px; */
  padding: 16px;
}

.box-list {
  /* border: 1px solid; */
  /* box-shadow: 1px 1px 1px 1px   #8c8c8c; */
  border-radius: 10px;
  background-color: #fafafa;
  padding: 20px 20px 20px 20px;
  /* margin-top: 20px;
  margin-right: 20px; */
}
/* page map */
.Box-Seach-map {
  width: 95%;
}
/* tooltip page map */
.ol-tooltip {
  color: white;
  background-color: #333333a6;
  border-radius: 5px;
  padding: 5px;
}
.Box-title {
  background-color: #025464bf;
  margin-top: 0px;
  /* font-weight: 900  !important; */
}
.content {
  margin: 30px 10px 10px 20px;
  padding: 10px 20px 20px 20px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 2px 3px 5px #02546445;
}
.backgroud {
  border-radius: 0px 0px 60px 0px;
  background-color: #e8aa42;
  box-shadow: 5px 5px 0px #e57c23;
}
.backgroud-formInput {
  color: aliceblue;
  border-radius: 10px 0px;
  background-color: #025464bf;
  /* box-sizing: content-box; */
  margin: 10px 0px 30px 0px;
  padding: 10px;
  width: 300px;
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: center;
}
.text-font {
  color: #f8f1f1;
  padding: 10px 10px 10px 35px;
  font-size: 2.5ch !important;
  letter-spacing: 1px;
}

/* box Title  map */
.Title-map {
  background-color: #ffff;
  padding: 20px 20px 20px 20px;
  width: 340px;
  color: #025464 !important;
  margin: 15px 10px 10px 40px;
  border-radius: 5px;
  box-shadow: 2px 3px 5px #02546445;
  text-align: center;
}

.cardHeaderStyle {
  /* bgcolor: 'info.main'; */
  background: #f2f3f3 !important;
  color: #025464 !important;
  padding: 1;
}

.font-title-pageSearchMap {
  padding-top: 15px;
  /* font-size: xx-large !important; */
  /* background-color: #fff !important; */
  color: #025464;
}
