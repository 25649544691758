
.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #107083;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
  cursor: pointer;
}

.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  color: #fff;
  display: block;
  transition: 0.3s;
  /* border-left: 4px solid #fff; */
}

.sidenav a:hover {
  color: #025464;
  background-color: #fff;
  border-left: 4px solid #025464;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

/* Survey App CSS */
.otherSide {
  /* background-image: url("/public/0001.jpg"); */
  transition: margin-left .5s;
  background-color: #025464;
  position: absolute; 
  /* height: 500px; */
  background-size: cover;
  min-width: -webkit-fill-available;
  min-height: -webkit-fill-available;
  padding-top: 75px;
} 
/* .tap-bar{
  background-color: #025464;
} */