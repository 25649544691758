.box {
  color: blue;
}
.Row-title {
  display: flex;
  flex-flow: row wrap;
  min-width: 0;
  justify-content: center;
  margin-bottom: 50px;
  color: #ffff;
}

.row-button {
  display: flex;
  flex-flow: row wrap;
  min-width: 0;
  justify-content: center;
  justify-content: center;
 
}
.size-button {
  width: 200px;
  height: 150px;
  /* background-color: #468591; */
  color: aliceblue;
  border:20px;
  box-shadow : 2px 2px 4px rgba(0, 0, 0, 0.5); 
  /* border-radius: 150px; */
  /* font-size: 15px;  */
}
.anticon-svg {
 display: inline-block;
 font-size: xx-large;
} 